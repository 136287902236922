.sidebar {
    height: 100vh;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow-y: auto;
    padding: 0px 16px 0px 16px;
}

.sidebar h1 {
    font-family: 'BethEllen-Regular', sans-serif;
    text-align: center;
    font-size: 4rem;
    color: var(--steeny-bone);
    line-height: 4.25rem;
}

.sidebar ul {
    list-style-type: none;
    text-align: start;
    border-radius: 16px;
}

.sidebar ul:hover {
    cursor: pointer;
}

.sidebar li {
    padding: 8px;
    font-size: 1.5rem;
    margin-bottom: 8px;
    color: var(--steeny-bone);
}

.sidebar li:hover {
    background-color: var(--steeny-brown50);
    border-radius: 8px;
}

.sidebar li.active {
    background-color: var(--steeny-brown);
    border-radius: 8px;
    color: var(--steeny-bone);
}

.sidebar footer {
    text-align: center;
    color: var(--steeny-bone);
    font-size: 1.15rem;
}

.clickable-text {
    color: var(--steeny-brown);
    cursor: pointer;
    text-decoration: none;
}

.sep {
    height: 1px;
    width: 100%;
    background-color: var(--steeny-bone);
}