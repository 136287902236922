  .rsvp-option {
      color: var(--steeny-brown);
      background-color: var(--steeny-bone);
      padding: 8px;
      border: 1px solid var(--steeny-brown);
      border-radius: 8px;
      font-size: 18px;
  }

  .rsvp-option:hover {
      cursor: pointer;
      background-color: var(--steeny-brown50);
      color: var(--steeny-bone);
  }

  .rsvp-selected {
      color: var(--steeny-bone);
      background-color: var(--steeny-brown);
  }