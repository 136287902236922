.searchBar {
    width: 100%;
    background: none;
    padding: 16px;
    font-size: 32px;
    color: var(--steeny-brown);
    border: 2px solid var(--steeny-brown);
    border-radius: 16px;
    outline: none;
}

.searchBar::placeholder {
    color: var(--steeny-brown);
    font-style: italic;
}

.rsvpItem {
    margin: 8px;
    border-radius: 16px;
    padding: 16px;
}

.results {
    border-radius: 8px;
    flex: 1;
    width: 100%;
}

.custom-theme input[type="checkbox"]:checked+.switcher {
    background: var(--primary-color20);
    /*this is body of switch that should be lighter color*/
}

.custom-theme input[type="checkbox"]:checked+.switcher:before {
    background: var(--primary-color);
    /*this is btn of switch that should be darker color*/
}

.rsvpHeader {
    justify-content: space-between;
    flex: 1;
}

.rsvpExpandedItemCell {
    padding: 8px 32px 8px 32px;
    flex: 1;
}

.rsvpExpandedItemCell:hover {
    background-color: var(--secondary-color20);
    border-radius: 8px;
    cursor: pointer;
}

.menuImage, .menuImageSelected {
    height: 100px;
    width: 80px;
    cursor: pointer;
    border-style: solid;
    border-color: var(--steeny-brown);
    border-width: 1px 1px 1px 1px;
    border-radius: 8px;
    transition: border-width 0.2s ease, transform 0.3s ease;
}

.menuImage:hover, .menuImageSelected:hover {
    transform: scale(1.05);
}

.menuImageSelected {
    border-width: 1px 1px 4px 1px;
}

a {
    color: blue; /* Normal color */
    text-decoration: none; /* Remove underline */
}

/* Style for the hyperlink when visited */
a:visited {
    color: blue; /* Color remains blue even after visiting */
}

a:hover {
    cursor: pointer;
}