.Rounded {
    height: 100%;
}

.Rounded img {
    border-radius: 16px;
    width: 400px;
    height: 300px;
    object-fit: cover;
    display: block;
}