.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 32px;
  }

  .gallery-item {
    height: auto;
  }

  .image-container {
    height: 100%; /* Set the desired height for the images */
    overflow: hidden; /* Hide any image overflow */
  }
  
  .image-container img {
    max-width: 100%; /* Ensure the image fits within the container */
    height: auto; /* Allow the image to maintain its aspect ratio */
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .image-container img:hover {
    transform: scale(1.01);
  }
  