.HomePage {
    height: 100vh;
    background-image: url("https://images.squarespace-cdn.com/content/v1/5d67f54999e03b0001ecc78e/1594309883942-NZ3SQ4RFJSSRO6Y9M4QW/DJI_0047.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.HomePage h1 {
    font-family: 'Montserrat-Regular', sans-serif;
}

.card-text {
    border-radius: 8px;
    background-color: var(--steeny-boneHomePage);
    border: 2px solid var(--steeny-bone);
    padding: 16px;
    width: fit-content;
}

.text-align-end {
    text-align: end;
}

.bottom-right {
    position: absolute;
    top: 0;
    right: 0;
    margin: 40px;
}

.elevated {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}