.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.dialog {
    background-color: var(--steeny-bone);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: left;
    max-width: 400px;
}

.dialog h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.dialog p {
    font-size: 16px;
    margin-bottom: 20px;
}

.dialog input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border-color: var(--steeny-brown);
    border-radius: 4px;
    outline: none;
}

.dialog-buttons {
    display: flex;
    justify-content: end;
}

.dialog-buttons button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit {
    color: white;
    background-color: var(--steeny-brown);
}