.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay img {
    max-width: 80vw;
    max-height: 80vh;
    align-self: center;
}

.close-button {
    position: absolute;
    top: 50px;
    right: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 8px;
    border: none;
    font-size: 30px;
    cursor: pointer;
    padding: 16px;
}

.close-button:hover {
    color: var(--steeny-brown);
}