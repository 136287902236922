/* Style the checkbox container */
.checkbox {
    display: flex;
    /* Align checkbox and label horizontally */
    align-items: center;
    /* Center vertically within the container */
    font-size: 16px;
    /* Change the text size */
    color: var(--steeny-bone);
    /* Change the text color */
    justify-content: center;
}

/* Style the checkbox input */
.checkbox input[type="checkbox"] {
    width: 20px;
    /* Change the checkbox size */
    height: 20px;
    margin-right: 18px;
    /* Add spacing between checkbox and label */
    /* Customize the checkbox appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid var(--steeny-brown);
    /* Add a border */
    border-radius: 4px;
    /* Round the corners */
}

/* Style the checkbox when checked */
.checkbox input[type="checkbox"]:checked {
    background-color: var(--steeny-brown);
    /* Change the background color when checked */
    border-color: var(--steeny-brown);
    /* Change the border color when checked */
}

/* Style the label text */
.checkbox label {
    cursor: pointer;
    /* Add pointer cursor for interaction */
}