.even {
    justify-content: space-evenly;
}

.primary {
    color: var(--steeny-brown)
}

.rotate {
    transform: rotate(-10deg);
}

.item {
    margin-top: 20px;
    transition: transform 0.3s ease;
}

.item:hover {
    transform: scale(1.01);
}

.icon {
    margin-left: 64px;
    margin-right: 32px;
    font-size: 64px;
}

.description {
    margin: 0px 64px 40px 64px;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    color: var(--steeny-brown);
    font-style: italic;
}

.clickable-hover {
    cursor: pointer;
}