:root {
  --primary-color: #03121b;
  --primary-color20: #03121b20;
  --secondary-color: #4e5d4e;
  --secondary-color20: #4e5d4e20;
  --secondary-color10: #4e5d4e08;
  --cliff-orange: #af5b30;
  --cliff-orange50: #af5b3080;
  --cliff-orange-rgb: 175, 91, 48;
  --stone: #d6c3ba;
  --sand: #e5dfc4;
  --sand-50: #e5dfc480;

  --steeny-bone: #eee4d3;
  --steeny-bone50: #eee4d380;
  --steeny-boneHomePage: #eee4d3be;
  --steeny-brown: #622f1b;
  --steeny-brown50: #622f1b80;

  --header-width: 300px;
  --radius-small: 4px;
  --radius-medium: 8px;
  --radius-large: 16px;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'BethEllen-Regular';
  src: url('fonts/BethEllen-Regular.ttf') format('truetype');
}

* {
  padding: 0;
  margin: 0;
  /* Some bs about padding moving parent */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: 'Montserrat-Regular', sans-serif;
}

.app {
  display: grid;
  grid-template-columns: 350px 1fr;
}

.app-mobile {
  display: grid;
  grid-template-columns: 150px 1fr;
}

.bkg-sand {
  background-color: var(--sand);
}

.bkg-steeny-bone {
  background-color: var(--steeny-bone);
}

.rem-1 {
  font-size: 1rem;
}

.rem-1_5 {
  font-size: 1.5rem;
}

.rem-2 {
  font-size: 2rem;
}

.rem-3 {
  font-size: 3rem;
}

.rem-4 {
  font-size: 4rem;
}

.font-fancy {
  font-family: 'BethEllen-Regular', sans-serif;
}

.width-full {
  width: 100%;
}

.page {
  height: 100vh;
  background-color: var(--steeny-bone);
  overflow: auto;
  padding: 32px;
}

/* PADDING */
.padding-16 {
  padding: 16px;
}

.padding-32 {
  padding: 32px;
}

.padding-64 {
  padding: 64px;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

/* ALIGNMENT */
.content-center {
  align-items: center;
  display: flex;
}

.content-end {
  align-items: end;
  display: flex;
}

/* BUILDING BLOCKS */
.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.row-wrap {
  display: inline-flex;
  flex-direction: row;
}

/* JUSTIFICATION */
.center {
  justify-content: center;
}

.end {
  justify-content: end;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-between {
  justify-content: space-between;
}

/* SPACER */
.spacer-w-4 {
  width: 4px;
}

.spacer-w-8 {
  width: 8px;
}

.spacer-w-16 {
  width: 16px;
}

.spacer-w-32 {
  width: 32px;
}

.spacer-w-64 {
  width: 64px;
}

.spacer-w-120 {
  width: 120px;
}

.spacer-h-5 {
  margin-top: 5px;
}

.spacer-h-10 {
  margin-top: 10px;
}

.spacer-h-16 {
  margin-top: 16px;
}

.spacer-h-25 {
  margin-top: 25px;
}

.spacer-h-50 {
  margin-top: 50px;
}

.primary-border-full {
  border-style: solid;
  border-color: var(--steeny-brown);
  border-width: 1px;
  border-radius: 16px;
}

.primary-border-full-selected {
  border-style: solid;
  border-color: var(--steeny-brown);
  border-width: 2px 2px 2px 14px;
  border-radius: 16px;
}

.primary-border-full-bottom {
  border-style: solid;
  border-color: var(--steeny-brown);
  border-width: 2px 2px 14px 2px;
  border-radius: 16px;
}

.row-divider {
  width: 1px;
  background-color: var(--steeny-brown);
}

.column-divider {
  height: 2px;
  background-color: var(--steeny-brown);
}