/* Default (unchecked) style */
.radioButton {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 2px solid var(--steeny-brown); /* Border color for the default state */
    border-radius: 50%; /* Make it circular */
    display: inline-block;
    vertical-align: middle;
}

/* Style the checkbox when checked */
.radioButton:checked {
    background-color: var(--steeny-brown);
    /* Change the background color when checked */
    border-color: var(--steeny-brown);
    /* Change the border color when checked */
}