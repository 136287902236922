.scroling-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.left {
    position: absolute;
    top: 80vh;
    right: 52.5vw;
    align-items: center;
}

.right {
    position: absolute;
    top: 80vh;
    left: 52.5vw;  
    padding: 20px;
    align-items: center;
}

.arrow-button {
    background-color: rgba(0, 0, 0, 0.80);
    color: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    padding: 16px;
}

.arrow-button:hover {
    color: var(--steeny-brown);
}