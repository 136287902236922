.align-center {
    align-items: center;
}

.hotel-info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.hotel-info-section {
    flex: 1;
    background-color: rgba(var(--cliff-orange-rgb), 0.05);
    padding: 32px;
    width: 100%;
    transition: transform 0.3s ease;
}

.hotel-info-section:hover {
    transform: scale(1.01);
}

.no-underline-link {
    text-decoration: none;
}

.zzzz {
    width: 100%;
}