.timeline {
    overflow-y: auto;
    height: 100vh;
}

.vertical-timeline-element-date {
    color: var(--steeny-brown);
    font-size: 1.5rem;
}

.vertical-timeline-element-icon {
    -webkit-box-shadow: 0 0 0 4px var(--steeny-brown);
    box-shadow: 0 0 0 4px var(--steeny-brown);
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline--two-columns .vertical-timeline-element-icon {
        width: 30px;
        height: 30px;
        left: 50%;
        top: 20%;
        margin-left: -15px;
    }
}

/* .vertical-timeline-element-content {
    box-shadow: none;
} */

/* .vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 4px var(--cliff-orange);
    box-shadow: 0 0 0 4px var(--cliff-orange);
  } */

.end {
    text-align: end;
}

.start {
    text-align: start;
}

.vertical-timeline-element-title {
    color: var(--sand);
    background-color: var(--steeny-brown);
    border-radius: 12px;
    padding-left: 16px;
}

/* .inside {
    color: var(--cliff-orange);
    display: flex;
    flex-direction: row;
    align-items: end;
} */

/* .vertical-timeline-element {
  position: relative;
  margin: 0;
} */