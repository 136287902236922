.box {
    position: relative;
    height: 300px;
    transition: transform 0.3s ease;
}

.box:hover {
    transform: scale(1.015);
    cursor: pointer;
}

.prev-button {
    background-color: rgba(0, 0, 0, 0.35);
    color: white;
    border-radius: 16px 0px 0px 16px;
    border: none;
    cursor: pointer;
    padding: 8px;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
}

.next-button {
    background-color: rgba(0, 0, 0, 0.35);
    color: white;
    border-radius: 0px 16px 16px 0px;
    border: none;
    cursor: pointer;
    padding: 8px;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
}

.prev-button:hover,
.next-button:hover {
    color: var(--steeny-brown);
}